<template>
  <section class="xa-container">
    <div class="xa-cell__box">照片</div>
    <div class="xa-cell__box xa-bg-white">
      <van-uploader
        v-model="images"
        :deletable="false"
        :show-upload="false"
      />
    </div>
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import { Uploader } from 'vant'
import resource from '@/controllers/resource'
export default {
  name: 'loss-image-preview',
  mixins: [basePage],
  components: {
    VanUploader: Uploader
  },
  config: {
    title: '图片查看'
  },
  data() {
    return {
      images: []
    }
  },
  methods: {
    async initUpladImg({ guids }) {
      if (Array.isArray(guids) === false) return
      if (guids.length <= 0) return
      const list = await this.$_fetchDataWidthUi(
        resource.postQiniuKey2Path(guids)
      )
      this.images = list.map(item => {
        return {
          url: item.thumb_url, // 展示的图片
          guid: item.uid, // 后台需要的guid
          status: 200, // 状态
          message: '上传成功'
        }
      })
    }
  },
  mounted() {
    const { params } = this.$route
    this.initUpladImg(params)
  }
}
</script>
